import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { getAllBookEpisodes, Episode } from '../../Common/realBackend/getAllBookEpisodes';

const inputStyle = {
  backgroundColor: '#374460',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  color: '#FFFFFF',
  borderRadius: '0',
  padding: '10px',
  fontSize: '1rem',
  width: '100%',
};

const SeriesList: React.FC = () => {
  const { bookId } = useParams<{ bookId: string }>();
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 576);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 576);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const episodeData = await getAllBookEpisodes(Number(bookId));
        setEpisodes(episodeData);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch episodes");
        setLoading(false);
      }
    };
    fetchEpisodes();
  }, [bookId]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleEpisodeClick = (episodeId: string) => {
    // Navigate to the StoryDetails page with the required parameters
    navigate(`/story-details/series/${bookId}/${episodeId}`);
  };

  const filteredEpisodes = episodes.filter((item) => 
    item.title.toLowerCase().includes(searchTerm)
  );

  if (loading) return <Container>Loading...</Container>;
  if (error) return <Container>{error}</Container>;

  return (
    <Container>
      <style>
        {`
          .custom-heading { font-size: 5rem; text-align: left; color: #fff; }
          .series-table { background-color: transparent; }
          .series-table th, .series-table td { background-color: transparent !important; border-bottom: none !important; vertical-align: middle; color: #fff; }
          .series-table tbody tr:hover { background-color: #1F2A40 !important; color: #fff !important; cursor: pointer; } /* Add cursor pointer */
          .series-image { width: 50px; height: 50px; object-fit: cover; margin-right: 10px; border-radius: 10%; }
          .form-control::placeholder { color: white; }
          .mobile-margin { margin-bottom: 20px; }
        `}
      </style>

      <h1 className="nba-font my-4 custom-heading">EPISODES</h1>

      <Row className="mb-3">
        <Col md={6} className={isMobile ? 'mobile-margin' : ''}>
          <Form.Group controlId="search">
            <Form.Control
              type="text"
              placeholder="Search by title"
              value={searchTerm}
              onChange={handleSearch}
              style={inputStyle}
            />
          </Form.Group>
        </Col>
      </Row>

      <Table hover responsive variant="dark" className="series-table">
        <thead>
          <tr>
            <th>Episode</th>
            <th>Title & Image</th>
          </tr>
        </thead>
        <tbody>
          {filteredEpisodes.length > 0 ? (
            filteredEpisodes.map((item, index) => (
              <tr key={item.id} onClick={() => handleEpisodeClick(item.id.toString())}> {/* Add onClick handler */}
                <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  <img src={item.coverImage || ''} alt={item.title} className="series-image" />
                  {item.title}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2} style={{ textAlign: 'center', color: '#fff' }}>
                No episodes available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default SeriesList;
