import { fetchData } from "../helpers/api_helper";

export interface CreateCommentPayload {
  content: string;
  referenceType: string;
  referenceId: string;
}

export const createComment = async (referenceId: string, payload: CreateCommentPayload) => {
  try {
    const url = `/api/v1/comments/${referenceId}`;
    return await fetchData(url, { method: "POST", body: JSON.stringify(payload) });
  } catch (error) {
    console.error("Failed to create comment:", error);
    throw error;
  }
};
