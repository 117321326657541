import { fetchData } from "../helpers/api_helper";

export interface Comment {
  id: number;
  content: string;
  replies: Comment[];
  timestamp: string;
  createdAt: string;
  user?: { username: string };
}

export interface CommentResponse {
  status: string;
  message: string;
  data?: {
    comments: Comment[];
  };
  comments?: Comment[];
}

export const getComments = async (referenceId: string, referenceType: string): Promise<CommentResponse> => {
  try {
    const url = `/api/v1/comments/${referenceId}/comments?referenceType=${referenceType}`;
    const response: CommentResponse = await fetchData(url, { method: "GET" });
    return response;
  } catch (error) {
    console.error("Failed to fetch comments:", error);
    throw error;
  }
};
