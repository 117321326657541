import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ominira from '../../assets/images/ominira-logo.svg'; // Adjust the import path for your image

const HomeContent: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 576); // Initialize with current screen width

  // Update isMobile based on screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 576 && isMobile) {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  // Styles for larger screens and mobile
  const headingStyle: React.CSSProperties = {
    fontSize: isMobile ? '8rem' : '13rem',
    marginBottom: isMobile ? '0' : '0', // Remove bottom margin for mobile
    color: 'white',
    textAlign: isMobile ? 'center' : 'left', // Center text on mobile
  };

  const paragraphStyle: React.CSSProperties = {
    fontSize: isMobile ? '1.25rem' : '1.75rem',
    marginTop: isMobile ? '0' : '0', // No margin for mobile
    color: 'white',
    textAlign: isMobile ? 'center' : 'left', // Center text on mobile
  };

  return (
    <Row className="mb-5 pb-5 d-flex justify-content-center align-items-center">
      {/* Image Section */}
      {isMobile && (
        <Col xs={12} className="text-center">
          <img
            src={ominira}
            alt="Description of the image"
            className="img-fluid"
            style={{ maxHeight: '200px', width: 'auto', marginBottom: '20px' }}
          />
        </Col>
      )}

      {/* Text Section */}
      <Col md={6} className={isMobile ? 'text-center' : 'text-left d-flex flex-column justify-content-center'}>
        <div style={{ width: '100%' }}>
          <h1 className="nba-font" style={headingStyle}>Òmìnira</h1>
          <p className="large-paragraph" style={paragraphStyle}>
            Freedom (from inner strife and emotional burdens)
          </p>
        </div>
      </Col>

      {/* Image for Desktop */}
      {!isMobile && (
        <Col md={6} className="text-right">
          <div className="d-flex justify-content-end">
            <img
              src={ominira}
              alt="Description of the image"
              className="img-fluid"
              style={{ maxHeight: '350px', width: 'auto' }}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default HomeContent;
