import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { getAllAmazonBooks, AmazonBook } from '../../Common/realBackend/getAllAmazonBooks';
import BookCard from './BookCard';

const Books: React.FC = () => {
  const [books, setBooks] = useState<AmazonBook[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await getAllAmazonBooks();
        setBooks(response); // Set the fetched books data
      } catch (err) {
        console.error("Error fetching books:", err);
        setError("Failed to load books. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchBooks();
  }, []);

  return (
    <Container>
      <style>
        {`
          .custom-heading {
            font-size: 5rem;
            text-align: left;
            color: #fff;
          }
        `}
      </style>

      <h1 className="nba-font my-4 custom-heading">BOOKS</h1>

      {loading ? (
        <div className="text-center my-5">
          <Spinner animation="border" role="status" className="text-light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">{error}</Alert>
      ) : (
        <Row>
          {books.map((book) => (
            <Col key={book.id} xs={6} md={4} className="mb-4">
              <BookCard
                title={book.title}
                coverImage={book.coverImage}
                amazonLink={book.amazonLink}
              />
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default Books;