import React, { useEffect, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import StoryContent from './StoryContent';
import VideoContent from './VideoContent';
import CommentSection from './CommentSection';
import ShareComponent from './ShareComponent';
import PageTransition from '../../Routes/transition';
import { getBookById } from '../../Common/realBackend/getBookById';
import { getEpisodeById } from '../../Common/realBackend/getEpisodeById';

const StoryDetails: React.FC = () => {
  const { type, id, episodeId } = useParams<{ type: string; id: string; episodeId?: string }>();
  const [darkMode, setDarkMode] = useState(true);
  const [activeTab, setActiveTab] = useState<string>("story");
  const [currentStory, setCurrentStory] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 576);
  const [padding, setPadding] = useState<string>('5rem 1rem');

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
      setPadding(window.innerWidth <= 576 ? '3rem 1rem' : '5rem 1rem');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchStory = async () => {
      try {
        if (type === "one-short") {
          const story = await getBookById(Number(id));
          setCurrentStory(story);
        } else if (type === "series" && episodeId) {
          const story = await getEpisodeById(Number(id), Number(episodeId));
          setCurrentStory(story);
        }
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    fetchStory();
  }, [id, episodeId, type]);

  if (!currentStory) {
    return <div>Loading...</div>;
  }

  // Dynamically set the share URL based on type and episodeId
  const shareUrl = type === "series" && episodeId
    ? `https://aaooa.net/#/story-details/series/${id}/${episodeId}`
    : `https://aaooa.net/#/story-details/one-short/${id}`;
    
  const shareTitle = currentStory.title;
  const referenceType = currentStory.type === "series" ? "series" : "one-short";

  return (
    <PageTransition>
      <div>
        <style>
          {`
            body {
              margin: 0;
              padding: 0;
            }
            .story-details-page {
              position: relative;
              min-height: 100vh;
              color: #000;
            }
            .story-container {
              position: relative;
              background-image: url(${currentStory.coverImage}); 
              background-size: cover;
              background-position: center;
              padding: ${padding};
              margin-bottom: 0;
              width: 100%; /* Ensure full width */
            }
            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              z-index: 1;
            }
            .dark-mode {
              background-color: #111827;
              color: #fff;
            }
            .light-mode {
              background-color: #f4f4f4;
              color: #000;
            }
            .tabs-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: ${isMobile ? '2rem 1rem' : '2rem 8rem'};
              width: 100%;
            }
            .tabs-and-toggle {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              flex-wrap: wrap;
              margin-bottom: 1rem;
            }
            .nav-tabs {
              border-bottom: none;
              justify-content: center;
              flex-grow: 1;
              display: flex;
              justify-content: center;
            }
            .tab-content {
              padding-top: 1rem;
              padding: ${isMobile ? '1rem 1rem' : '0'};
            }
            .toggle-switch {
              position: relative;
              display: inline-block;
              width: 60px;
              height: 34px;
            }
            .toggle-switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }
            .toggle-slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              transition: .4s;
              border-radius: 34px;
            }
            .toggle-slider:before {
              position: absolute;
              content: "";
              height: 26px;
              width: 26px;
              left: 4px;
              bottom: 4px;
              background-color: white;
              transition: .4s;
              border-radius: 50%;
            }
            input:checked + .toggle-slider {
              background-color: #66bb6a;
            }
            input:checked + .toggle-slider:before {
              transform: translateX(26px);
            }
            .icon {
              font-size: 1.5rem;
              margin: 0 8px;
              position: relative;
            }
            .icon-sun {
              color: ${darkMode ? '#fff' : '#875CFF'};
            }
            .icon-moon {
              color: ${darkMode ? '#875CFF' : '#333'};
              background: ${darkMode ? 'none' : '#f4f4f4'};
              border-radius: 50%;
              padding: ${darkMode ? '0' : '5px'};
            }
            @media (max-width: 576px) {
              .tabs-container {
                padding: 2rem 0rem;
              }
              .tabs-and-toggle {
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
              }
              .tab-content {
                padding: 0 1rem;
              }
            }
          `}
        </style>

        <div className="story-details-page">
          <div className="story-container">
            <div className="overlay"></div>
            <Container className="position-relative" style={{ zIndex: 2, width: '100%' }}>
              <h1 className="nba-font" style={{ fontSize: '5rem', color: '#fff' }}>
                {currentStory.title}
              </h1>
              <p style={{ color: '#fff' }}>{currentStory.date}</p>
              <AudioPlayer audioSrc={currentStory.audioLink} />
            </Container>
          </div>

          <Container fluid className={`tabs-container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <div className="tabs-and-toggle">
              <Tabs 
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k || "story")}
                id="story-tabs" 
                className="nav-tabs"
              >
                <Tab eventKey="story" title="Story" />
                <Tab eventKey="video" title="Video" />
              </Tabs>
              <div>
                <i className={`bi bi-sun icon icon-sun`}></i>
              </div>
              <label className="toggle-switch">
                <input type="checkbox" onChange={toggleTheme} checked={darkMode} />
                <span className="toggle-slider"></span>
              </label>
              <div>
                <i className={`bi bi-moon icon icon-moon`}></i>
              </div>
            </div>

            <div className="tab-content">
              {activeTab === "story" && (
                <div>
                  <StoryContent 
                    content={currentStory.description} 
                    fontSize={isMobile ? '0.875rem' : '1rem'} 
                    padding={isMobile ? '2rem 1rem' : '2rem 3rem'} 
                  />
                  <div style={{ padding: isMobile ? '1rem 1rem' : '0' }}>
                    <ShareComponent url={shareUrl} title={shareTitle} />
                  </div>
                </div>
              )}
              {activeTab === "video" && <VideoContent videoLink={currentStory.videoLinkString} />}
            </div>

            <div className="mt-5" style={{ width: '100%', padding: '0' }}>
              <CommentSection bookId={currentStory.id} referenceType={referenceType} />
            </div>

          </Container>
        </div>
      </div>
    </PageTransition>
  );
};

export default StoryDetails;