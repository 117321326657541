import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { FaGoogle } from 'react-icons/fa';  // Import Google icon from react-icons
import { loginUser } from '../../Common/realBackend/login'; // Import the login API function

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await loginUser({ email, password });
      if (response.status === 'success') {
        // Store the entire response object as a JSON string in local storage
        localStorage.setItem('authResponse', JSON.stringify(response));
        setSuccessMessage('Login successful!');
        // Redirect or perform other actions here after successful login
      } else {
        setError(response.message); // Display error message from API
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to log in. Please try again later.'); // Generic error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleGoogleLogin = () => {
    // Add Google login logic here, such as redirecting to Google OAuth
    console.log('Logging in with Google');
  };

  return (
    <Container fluid className="py-5" style={{ color: 'white' }}> {/* Set text color to white */}
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <h2 className="text-center mb-4">Sign In</h2>

          {/* Display success or error messages */}
          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mb-3" disabled={loading}>
              {loading ? 'Signing in...' : 'Sign In'}
            </Button>
          </Form>

          <Button
            variant="light"
            className="w-100 mb-3 d-flex align-items-center justify-content-center"
            onClick={handleGoogleLogin}
          >
            <FaGoogle className="me-2" /> Sign in with Google
          </Button>

          <div className="text-center mt-3">
            <a href="/forgot-password" style={{ color: 'white' }}>Forgot password?</a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
