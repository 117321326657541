import React, { useEffect, useState } from 'react';
import { Container, Table, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { getAllBooks, Book } from '../../Common/realBackend/getAllBooks';

const inputStyle = {
  backgroundColor: '#374460',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  color: '#FFFFFF',
  borderRadius: '0',
  padding: '10px',
  fontSize: '1rem',
  width: '100%',
};

const Stories: React.FC = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [books, setBooks] = useState<Book[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 576);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 576);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const booksData = await getAllBooks();
        setBooks(booksData);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch books");
        setLoading(false);
      }
    };
    fetchBooks();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleFilterChange = (e: React.ChangeEvent<any>) => {
    setFilterType(e.target.value);
  };

  const handleRowClick = (book: Book) => {
    if (book.type.toLowerCase() === 'one-short') {
      navigate(`/story-details/one-short/${book.id}`); // Use navigate to go to story details
    } else if (book.type.toLowerCase() === 'series') {
      navigate(`/series-list/${book.id}`); // Use navigate to go to series list
    }
  };

  const filteredStories = books.filter((book) => {
    const matchesSearch = book.title.toLowerCase().includes(searchTerm);
    const matchesFilter =
      filterType === 'all' || book.type.toLowerCase() === filterType.toLowerCase();
    return matchesSearch && matchesFilter;
  });

  if (loading) return <Container>Loading...</Container>;
  if (error) return <Container>{error}</Container>;

  return (
    <Container>
      <style>
        {`
          .custom-heading { font-size: 5rem; text-align: left; color: #fff; }
          .story-table { background-color: transparent; }
          .story-table th, .story-table td { background-color: transparent !important; border-bottom: none !important; vertical-align: middle; }
          .story-table tbody tr:hover { background-color: #1F2A40 !important; color: #fff !important; }
          .story-image { width: 50px; height: 50px; object-fit: cover; margin-right: 10px; border-radius: 10%; }
          .date-column { width: 20%; }
          .form-control::placeholder { color: white; }
          .mobile-margin { margin-bottom: 20px; }
        `}
      </style>

      <h1 className="nba-font my-4 custom-heading">STORIES</h1>

      <Row className="mb-3">
        <Col md={6} className={isMobile ? 'mobile-margin' : ''}>
          <Form.Group controlId="search">
            <Form.Control
              type="text"
              placeholder="Search by title"
              value={searchTerm}
              onChange={handleSearch}
              style={inputStyle}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="filter">
            <Form.Control as="select" value={filterType} onChange={handleFilterChange} style={inputStyle}>
              <option value="all">All</option>
              <option value="series">Series</option>
              <option value="one-shot">One-Shot</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Table hover responsive variant="dark" className="story-table">
        <thead>
          <tr>
            {!isMobile && <th className="date-column">Date</th>}
            <th>Title & Image</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {filteredStories.map((book) => (
            <tr key={book.id} onClick={() => handleRowClick(book)} style={{ cursor: 'pointer' }}>
              {!isMobile && <td style={{ verticalAlign: 'middle' }}>{new Date(book.createdAt).toLocaleDateString()}</td>}
              <td style={{ verticalAlign: 'middle' }}>
                <img src={book.coverImage || ''} alt={book.title} className="story-image" />
                {book.title}
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                {book.type.toLowerCase() === 'one-short'
                  ? 'One-Shot'
                  : book.type.toLowerCase() === 'series'
                  ? 'Series'
                  : book.type}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Stories;
