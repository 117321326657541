import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { FaGoogle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { signUpUser } from '../../Common/realBackend/signUp';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';

const SignUpSchema = Yup.object().shape({
  fullName: Yup.string().required('Full name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
});

const SignUp: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSignUp = async (values: any) => {
    const { fullName, email, password } = values;

    const [firstName, ...lastNameArr] = fullName.split(' ');
    const lastName = lastNameArr.join(' ') || '';
    const userName = email.split('@')[0];
    const role = 'user';

    const payload = {
      email,
      firstName,
      lastName,
      userName,
      password,
      role,
    };

    console.log('Payload:', payload); // Log the payload before sending it

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await signUpUser(payload);
      if (response.status === 'success') {
        localStorage.setItem('authToken', response.message);
        setSuccessMessage('User signed up successfully!');
      } else {
        setError(response.message);
      }
    } catch (err) {
      console.error('Sign up error:', err);
      setError('Failed to sign up. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container fluid className="py-5" style={{ color: 'white' }}>
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <h2 className="text-center mb-4">Sign Up</h2>

          {error && <Alert variant="danger">{error}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <Formik
            initialValues={{ fullName: '', email: '', password: '' }}
            validationSchema={SignUpSchema}
            onSubmit={handleSignUp}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <Form.Group controlId="formFullName" className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Field
                    name="fullName"
                    type="text"
                    placeholder="Enter your full name"
                    className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                  />
                  {touched.fullName && errors.fullName ? (
                    <div className="invalid-feedback">{errors.fullName}</div>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                  />
                  {touched.email && errors.email ? (
                    <div className="invalid-feedback">{errors.email}</div>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <div className="input-group">
                    <Field
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowPassword(!showPassword)}
                      className="input-group-text"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                    {touched.password && errors.password ? (
                      <div className="invalid-feedback d-block">{errors.password}</div>
                    ) : null}
                  </div>
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100 mb-3" disabled={loading}>
                  {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                  {!loading && 'Sign Up'}
                </Button>
              </FormikForm>
            )}
          </Formik>

          <Button
            variant="light"
            className="w-100 mb-3 d-flex align-items-center justify-content-center"
            onClick={() => console.log('Signing up with Google')}
          >
            <FaGoogle className="me-2" /> Sign up with Google
          </Button>

          <div className="text-center mt-3">
            <a href="/login" style={{ color: 'white' }}>
              Already have an account? Sign in
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
