import React from 'react';
import { Card, Button } from 'react-bootstrap';

interface BookCardProps {
  title: string;
  coverImage: string;
  amazonLink: string;
}

const BookCard: React.FC<BookCardProps> = ({ title, coverImage, amazonLink }) => {
  return (
    <Card className="mb-4"> {/* Removed fixed width to make it responsive */}
      <Card.Img variant="top" src={coverImage} alt={title} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Button
          variant="outline-success"
          href={amazonLink}
          target="_blank" // Opens the link in a new tab
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
            alt="Get on Amazon"
            style={{ width: '20px', marginRight: '5px' }} // Adjust the logo size as needed
          />
          Get on Amazon
        </Button>
      </Card.Body>
    </Card>
  );
};

export default BookCard;
