import Home from '../pages/Home';
import About from '../pages/About';
import StoryDetails from '../pages/Stories/StoryDetails';
import StoryList from '../pages/Stories/StoryList';  // Import the new StoryList component
import Books from '../pages/Book';
import SeriesList from '../pages/Stories/SeriesListNew';
import Login from '../pages/Auth/login';
import SignUp from '../pages/Auth/signup';

// Define your routes
const authProtectedRoutes = [
    { path: "/", component: <Home /> },            // Dashboard route set to "/"
    { path: "/about", component: <About /> },      // About page route
    { path: "/story-details/:type/:id/:episodeId?", component: <StoryDetails /> },
    { path: "/story-list", component: <StoryList /> },  // Add the StoryList route
    { path: "/series-list/:bookId", component: <SeriesList /> },  // Add the StoryList route
    { path: "/book", component: <Books /> },
    { path: "/login", component: <Login /> },
    { path: "/signup", component: <SignUp /> },
];

export { authProtectedRoutes };
